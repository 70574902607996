import React from 'react'
import styled from 'styled-components'

import ArrowDown from '../../images/arrow_down.svg'
import ArrowUp from '../../images/arrow_up.svg'
import ArrowLeft from '../../images/arrow_left.svg'
import ArrowRight from '../../images/arrow_right.svg'

const Container = styled.div`
  min-height: 100vh;
  padding: 100px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  margin: 2rem;
  font-family: 'Stileman', Arial, Helvetica, sans-serif;
  color: #fff;

  &.top {
    background-color: #8B8681;
    cursor: url(${ArrowUp}) 16 0, pointer;
  }

  &.left {
    background-color: #9A948F;
    cursor: url(${ArrowLeft}) 0 16, pointer;
  }

  &.right {
    background-color: #A8A39F;
    cursor: url(${ArrowRight}) 51 16, pointer;
  }
  &.bottom {
    background-color: #B8B3AE;
    cursor: url(${ArrowDown}) 16 51, pointer;
  }
`

const PointerTest = () => {
  return (
    <Container>
      <Block className="left">left</Block>
      <Block className="top">up</Block>
      <Block className="right">right</Block>
      <Block className="bottom">down</Block>
      <Block className="left">left</Block>
      <Block className="top">up</Block>
      <Block className="right">right</Block>
      <Block className="bottom">down</Block>
    </Container>
  )
}

export default PointerTest;
