import React from 'react'
import PointerTest from '../components/pointertest'

const PointerTestPage = () => {
  return (
    <>
      <PointerTest />
    </>
  )
}

export default PointerTestPage;
